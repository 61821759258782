/* ========================================================================
 * BLULAB SCRIPT
 * ======================================================================== */

(function($) {
  $(document).ready(function(){

    // Configurazione default slider
    $(".default-slider .slides").lightSlider({
        item: 1,
        autoWidth: false,
        slideMove: 1, // slidemove will be 1 if loop is true
        slideMargin: 0,
        addClass: '',
        mode: "fade",
        useCSS: true,
        cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
        easing: 'linear', //'for jquery animation',////

        speed: 800, //ms'
        auto: true,
        loop: true,
        slideEndAnimatoin: true,
        pause: 7000,
        keyPress: false,
        controls: true,
        prevHtml: '',
        nextHtml: '',
        pager: false,
        galleryMargin: 0,
        rtl: false,
        adaptiveHeight: true,
        enableTouch: true,
        enableDrag: true,
        freeMove: true,
        swipeThreshold: 40,
        responsive: [],

        onSliderLoad: function() {
          $('.default-slider .slides').removeClass('cS-hidden');
        }
    });


    $('.product-carousel .default-carousel').lightSlider({
        item:5,
        loop:true,
        slideMove:1,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed:600,
        pause: 3000,
        auto: true,
        slideMargin: 50,
        keyPress: true,
        prevHtml: '',
        nextHtml: '',
        pager: false,
        responsive : [
            {
                breakpoint:991,
                settings: {
                    item:3,
                    slideMove:1,
                    slideMargin:20,
                  }
            },
            {
                breakpoint:768,
                settings: {
                    item:2,
                    slideMove:1
                  }
            },
            {
                breakpoint:480,
                settings: {
                    item:1,
                    slideMove:1
                  }
            }
        ]
    });


    // Equal Wrapper
    $('.equal-wrapper').imagesLoaded().always(function(instance) {
      //Masonry per  Lista pubblicazioni
      $('.equal-wrapper').equalize({
          children: '.equal-el'
      });
    });
    
    $(".balza-gallery").lightGallery({
        selector: '.cinciu',
        counter: true,
        autoplay: true,
        progressBar: false,
        speed: 900,
        pause: 2000
        
    }); 
    
    
  });
})(jQuery); // Fully reference jQuery after this point.
